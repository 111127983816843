<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00033 8.00016C9.87974 8.00016 11.4033 6.50778 11.4033 4.66683C11.4033 2.82588 9.87974 1.3335 8.00033 1.3335C6.12091 1.3335 4.59734 2.82588 4.59734 4.66683C4.59734 6.50778 6.12091 8.00016 8.00033 8.00016Z"
      fill="#333333"
    />
    <path
      d="M2.6888 13.3416C3.23627 10.6656 5.40648 8.66683 8.00033 8.66683C10.5942 8.66683 12.7644 10.6656 13.3118 13.3416C13.4596 14.0637 12.8357 14.6668 12.0839 14.6668H3.91675C3.16498 14.6668 2.54109 14.0637 2.6888 13.3416Z"
      fill="#333333"
    />
  </svg>
</template>
  
  <script>
import { Vue } from "vue-class-component";

export default class IconProfileFill extends Vue {}
</script>
  